import * as React from 'react';
import {useContext} from 'react';
import Box from '@mui/material/Box';
import {RawDataContext, ScreenContext} from "../GlobalComponents";
import {useLocation} from "react-router-dom";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';

export function ProgramOverview() {

    const [rawData, setRawData] = useContext(RawDataContext)

    const locator = useLocation();

    const [currentScreen, setCurrentScreen] = useContext(ScreenContext)

    setCurrentScreen("Program Overview")

    const programName = parsePathForName(locator.pathname)

    function formatData(programName) {

        let formattedData = []

        let programData = rawData.filter(set => set.program === programName)

        programData.forEach((set, index) => {
            formattedData.push({
                    id: index,
                    Exercise: set.Exercise,
                    Substitution: set.Substitution,
                    Reps: set.Reps,
                    Weight: set.Weight,
                    Notes: set.Notes
                }
            )
        });

        return formattedData
    }




    console.log()

    const rows = []

    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'Exercise',
            headerName: 'Exercise',
            width: 150,
            editable: false,
        },
        {
            field: 'Substitution',
            headerName: 'Substitution',
            width: 150,
            editable: false,
        },
        {
            field: 'Weight',
            headerName: 'Weight',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'Reps',
            headerName: 'Reps',
            type: 'number',
            width: 110,
            editable: false,
        },
        {
            field: 'Notes',
            headerName: 'Notes',
            width: 150,
            editable: false,
        },

    ];


    return (
        <Box sx={{height: 800, width: '100%'}}>
            <DataGrid
                rows={formatData(programName)}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 15,
                        },
                    },
                }}
                pageSizeOptions={[15]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );

    return (
        <>
            {/*<Title>Recent Orders</Title>*/}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Exercise</TableCell>
                        <TableCell>Substitution</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Reps</TableCell>
                        <TableCell>Day</TableCell>
                        <TableCell>Notes</TableCell>
                        {/*<TableCell align="right">Sale Amount</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rawData.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.Exercise}</TableCell>
                            <TableCell>{row.Substitution}</TableCell>
                            <TableCell>{row.Weight}</TableCell>
                            <TableCell>{row.Reps}</TableCell>
                            <TableCell>{row.Day}</TableCell>
                            <TableCell>{row.Notes}</TableCell>
                            {/*<TableCell align="right">{`$${row.amount}`}</TableCell>*/}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/*<Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>*/}
            {/*    See more orders*/}
            {/*</Link>*/}
        </>
    );

}

function parsePathForName(programName) {

    const splitPath = programName.split("/");
    const numberOfPathElements = programName.split("/").length;
    const final = splitPath[numberOfPathElements - 1];

    let name = final.replaceAll("%20", " ")

    return name

}