import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";
import {LineChart, ScatterChart} from "@mui/x-charts";
import {FormControl, FormControlLabel, ListItem, Radio, RadioGroup, Stack, Tab, Tabs} from "@mui/material";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {FixedSizeList} from 'react-window';
import * as _ from "lodash"

import axios from "axios";
import Title from "../Components/Title";
import {BarChart} from "@mui/x-charts/BarChart";
import {ListOfExercisesContext, RawDataContext, ScreenContext} from "../GlobalComponents";
import {useCookies} from "react-cookie";
import Grid from "@mui/material/Grid";

export function SpecificExercise() {

    const [currentScreen, setCurrentScreen] = useContext(ScreenContext)

    setCurrentScreen("Specific Exercise")


    const [currentTab, setCurrentTab] = useState("Lifting")

    const [maxData, setMaxData] = useState();

    return (<>
            <ExerciseTabs currentTab={currentTab} setCurrentTab={setCurrentTab}/>

            {currentTab === "Lifting" ? <LiftingCharts
                myKey={["Weight_max"]}
                myTitle={["Max Weight"]}/> : <RunningCharts/>}

        </>


    )
}

function LiftingCharts() {

    const [rawData, setRawData] = useContext(RawDataContext)

    const [listOfExercises, setListOfExercises] = useContext(ListOfExercisesContext)

    const [whichPlot, setWhichPlot] = useState("Time")

    const [selectedExercise, setSelectedExercise] = useState(null);

    let actualListOfExercises = _.uniq(rawData.map( set => {
        return set.Exercise
    }))

    actualListOfExercises = actualListOfExercises.filter( exercise => {
        return exercise !== "unknown"
    })

    return (<>
            <Stack spacing={2} justifyContent="center"
                   alignItems="left" sx={{"paddingTop": "50px"}}>

                <RowRadioButtonsGroup whichPlot={whichPlot} setWhichPlot={setWhichPlot}/>

                <Stack spacing={2} justifyContent="center" direction="row" minWidth={1000}
                       alignItems="center">
                    <VirtualizedList listOfExercises={actualListOfExercises} setSelectedExercise={setSelectedExercise}/>
                    {whichPlot === "Time" ? <>
                        <LiftingTimeSeriesChart rawData={rawData} selectedExercise={selectedExercise}/>
                    </> : <LiftingWeightVsRepsChart rawData={rawData} selectedExercise={selectedExercise}/>}
                </Stack>


            </Stack>
        </>

    )

}

function LiftingTimeSeriesChart({ rawData, selectedExercise}) {

    let x = []
    let y = []

    const selectedData = rawData.filter(set => {
        return set.Exercise === selectedExercise && set.Weight !== "unknown"
    })

    for (let i = 0; i < selectedData.length; i++) {
        x.push(i)
        y.push(selectedData[i].Weight)
    }

    return (
        <React.Fragment>
            <LineChart
                height={600}

                xAxis={
                    [
                        {
                            data: x,
                            label: "Set #"
                        },

                    ]}
                yAxis={[{
                    label: "Weight (lbs)"

                }]}
                series={[
                    {
                        data: y,
                    },

                ]}

            />
        </React.Fragment>
    );
}

function LiftingWeightVsRepsChart({rawData, selectedExercise}) {

    const myData = rawData.filter(set => {
        return set.Exercise === selectedExercise && set.Weight !== "unknown"
    })

    const data1 = null; // myData.filter({column: "Exercise", is: "==", to: exercises[whichExercise]})

    return (<ScatterChart
        height={600}

        series={[
            {
                data: myData.map((v) => ({x: v.Reps, y: v.Weight})),
            },
        ]}
        grid={{vertical: true, horizontal: true}}
        xAxis={[{label: "Reps"}]}
        yAxis={[{label: "Weight (lbs)"}]}

    />);
}

function ExerciseTabs(props) {

    // const [value, setValue] = useState("Lifting")

    return (<>
        <Tabs
            value={props.currentTab}
            onChange={(e, v) => {
                props.setCurrentTab(v)
            }}
            variant="fullWidth"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
        >
            <Tab icon={<FitnessCenterIcon/>} label="Lifting" value={"Lifting"}/>
            <Tab icon={<DirectionsRunIcon/>} label="Running" value={"Running"}/>

        </Tabs>
        {/*<h1>{props.currentTab}</h1>*/}

    </>)

}

function RunningCharts(props) {

    return (<>
        {/*<Title name={""}></Title>*/}
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center-top"
            sx={{ minHeight: '100vh', "marginTop": "100px" }}
        >
            Feature Coming Soon!
        </Grid>
    </>)

}

function RowRadioButtonsGroup(props) {
    return (


        <FormControl sx={{"padding-left": "35%"}}>


            {/*<FormLabel id="demo-row-radio-buttons-group-label">This Month's Data</FormLabel>*/}
            <RadioGroup

                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={props.whichPlot}
                onChange={(event) => {
                    props.setWhichPlot(event.target.value)
                }}
            >
                <FormControlLabel value="Time" control={<Radio/>} label="Sets over Time"/>
                <FormControlLabel value="Reps vs. Weight" control={<Radio/>} label="Reps vs. Weight"/>

            </RadioGroup>
        </FormControl>
    );
}

function VirtualizedList( { listOfExercises, setSelectedExercise } ) {

    function renderRow(props) {
        const {index, style} = props;

        return (
            <ListItem style={style} key={listOfExercises[index]} component="div" disablePadding>
                <ListItemButton onClick={e => setSelectedExercise(e.target.innerText)}>
                    <ListItemText primary={listOfExercises[index]}/>
                </ListItemButton>
            </ListItem>
        );
    }


    return (
        <Box
            sx={{width: '100%', height: 500, maxWidth: 250, bgcolor: 'background.paper', border: '1px solid grey'}}
        >
            <FixedSizeList
                height={500}
                // width={360}
                itemSize={46}
                itemCount={listOfExercises.length}
                itemCount={listOfExercises.length}
                overscanCount={5}
            >
                {renderRow}
            </FixedSizeList>
        </Box>
    );
}